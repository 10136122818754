import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import useAuth from "../../hooks/useAuth";
import useRole from "../../hooks/useRole";
import useAgs from "../../hooks/useAgs";
import { useImmer } from "use-immer";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions";
import Spinner from "../UI/Spinner/Spinner";
import useRoles from "../../hooks/useRoles";
import customLazyLoad from "./CustomLazyLoader";
import useObserverStatusByRole from "../../hooks/useObserverStatusByRole";

// Custom lazy-loaded components
const Login = customLazyLoad(() => import("../Login/Login"));
const RegisterScreen = customLazyLoad(() =>
  import("../../containers/Register/Register")
);
const NotFoundPage = customLazyLoad(() =>
  import("../../containers/404/404NotFound")
);
const PasswordNewPublic = customLazyLoad(() =>
  import("../UserSettings/Password/PasswordNewPublic")
);
const RealtimeMap = customLazyLoad(() =>
  import("../../containers/RealtimeMap/index")
);
const AppLayout = customLazyLoad(() => import("../../hoc/AppLayout/AppLayout"));
const Analytics = customLazyLoad(() =>
  import("../../containers/Analytics/Analytics")
);
const SensorManager = customLazyLoad(() =>
  import("../../containers/SensorManager/SensorManager")
);
const UserSettings = customLazyLoad(() =>
  import("../../containers/UserSettings/UserSettings")
);
const WebViewer = customLazyLoad(() =>
  import("../../containers/WebViewer/WebViewer")
);
const MapTilerWebViewer = customLazyLoad(() =>
  import("../../containers/WebViewer/MapTilerWebViewer/MapTilerWebViewer")
);
const UserManagement = customLazyLoad(() =>
  import("../../containers/UserManager/UserManager")
);
const ChangeEmail = customLazyLoad(() =>
  import("../../containers/ChangeEmail/ChangeEmail")
);
// const PublicView = customLazyLoad(() =>
//   import("../../containers/RealtimeMap/PublicView/PublicView")
// );
const PublicView = customLazyLoad(() =>
  import("../../containers/RealtimeMap/PublicView/PublicView")
);
const DefaultScreen = customLazyLoad(() =>
  import("../../containers/DefaultScreen/DefaultScreen")
);

const AppRouter = () => {
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const userRole = useRole();
  const userRoles = useRoles();
  const ags = useAgs();
  const [appMode, setAppMode] = useState();
  // let appMode = useObserverStatusByRole() ? "observer" : "non-observer";
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActiveModuleLoaded, setIsActiveModuleLoaded] = useState(true);

  const userData = useSelector((state) => state.user?.data);
  const isUserLoading = useSelector((state) => state.user?.isLoading);
  const isCustomerLoading = useSelector((state) => state.customer?.isLoading);
  const activeModules = useSelector(
    (state) => state.customer?.data?.activeModules
  );
  // const selectedObserverAgs = useSelector(
  //   (state) => state.observer.selectedAgs
  // );

  // set app mode based on useObserverStatusByRole
  const useObserverStatus = useObserverStatusByRole();
  useEffect(() => {
    setAppMode(useObserverStatus ? "observer" : "non-observer");
  }, [useObserverStatus]);

  const selectedAgs = useSelector((state) => state.observer.selectedAgs);

  // //console.log("selectedObserverAgs", selectedAgs);

  const [privateRoutes, setPrivateRoutes] = useImmer([
    {
      path: "/realtimemap",
      // path: "/realtimemap/:sensorIdFromNotification?",
      components: <RealtimeMap />,
      allowedUser: ["users", "rescue", "managers", "observer"],
      isActive: true,
    },
    {
      path: "/sensormanager",
      components: <SensorManager />,
      allowedUser: ["rescue", "managers", "observer"],
      isActive: true,
    },
    {
      path: "/usermanagement",
      components: <UserManagement />,
      allowedUser: ["managers"],
      isActive: true,
    },
    {
      path: "/analytics",
      components: <Analytics />,
      allowedUser: ["rescue", "managers"],
      isActive: true,
    },
    // {
    //   path: "/webviewer",
    //   components: <WebViewer />,
    //   allowedUser: ["rescue", "managers", "observer"],
    //   isActive: false,
    // },
    {
      path: "/webviewer",
      components: <MapTilerWebViewer />,
      allowedUser: ["rescue", "managers", "observer"],
      isActive: true,
    },
    // {
    //   path: "/setting",
    //   components: <UserSettings />,
    //   allowedUser: ["users", "rescue", "managers", "observer"],
    //   isActive: true,
    // },
  ]);

  const appLoader = (
    <div className="absolute z-10 flex flex-col items-center justify-center left-1/2 top-1/2">
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    </div>
  );

  let publicRoutes = [
    {
      path: "/",
      components: <DefaultScreen />,
    },
    {
      path: "/login",
      components: <Login />,
    },
    {
      path: "/register",
      components: <RegisterScreen />,
    },
    {
      path: "/forgetpassword",
      components: <PasswordNewPublic />,
    },
    {
      path: "/ags/:id",
      components: <PublicView />,
    },
  ];

  // ======importance========
  // swithing between observer and non-observer based on observer area selection
  // non-obsever can access all modules
  // observer can access only webviewer, realtime-map
  const homeAgs = useSelector((state) => state.user?.data?.subscriber?.ags);
  useEffect(() => {
    if (!homeAgs) return;
    if (selectedAgs === homeAgs) {
      setAppMode("non-observer");
    } else {
      setAppMode("observer");
    }
  }, [selectedAgs, homeAgs]);

  // ========================
  // Fix code for special user who has only webviewer enabled
  // const userData = useSelector((state) => state.user.data);
  const customerData = useSelector((state) => state.customer.data);
  useEffect(() => {
    // if (selectedAgs) return;

    if (!customerData) return;
    if (customerData?.activeModules["realtimeMap"]) {
      return;
    }
    if (!userData) return;
    if (userData?.subscriber) {
      const ags = userData?.subscriber?.ags;
      dispatch(actions.selectObserverAgs(ags));
    } else if (Object.keys(userData?.roles).length === 1) {
      const ags = Object.keys(userData?.roles)[0];
      dispatch(actions.selectObserverAgs(ags));
    }
  }, [dispatch, userData, customerData]);

  useEffect(() => {
    if (!userData) return;

    // exmple assignedModules = ["realtimeMap", "sensorManager", "userManagement", "analytics", "webViewer"]
    // inject userRole to privateRoutes
    setPrivateRoutes((draft) => {
      if (userData?.assignedModules) {
        userData.assignedModules.forEach((module) => {
          if (module === "realtimemap") {
            // add if not exist
            if (!draft[0].allowedUser.includes(userRole)) {
              draft[0].allowedUser.push(userRole);
            }
            draft[0].isActive = true;
          } else if (module === "sensormanager") {
            if (!draft[1].allowedUser.includes(userRole)) {
              draft[1].allowedUser.push(userRole);
            }
            draft[1].isActive = true;
          } else if (module === "usermanagement") {
            if (!draft[2].allowedUser.includes(userRole)) {
              draft[2].allowedUser.push(userRole);
            }
            draft[2].isActive = true;
          } else if (module === "analytics") {
            if (!draft[3].allowedUser.includes(userRole)) {
              draft[3].allowedUser.push(userRole);
            }
            draft[3].isActive = true;
          } else if (module === "webviewer") {
            if (!draft[4].allowedUser.includes(userRole)) {
              draft[4].allowedUser.push(userRole);
            }
            draft[4].isActive = true;
          }
        });
      }
    });

    if (!activeModules) return;
    let keys = Object.keys(activeModules);
    setPrivateRoutes((draft) => {
      keys.forEach((key) => {
        if (key === "realtimeMap") {
          //realtime map
          draft[0].isActive = activeModules[key];
        } else if (key === "sensorManager") {
          //sensor manager
          draft[1].isActive = activeModules[key];
        } else if (key === "userManagement") {
          // user management
          draft[2].isActive = activeModules[key];
        } else if (key === "analytics") {
          //analytics
          draft[3].isActive = activeModules[key];
        } else if (key === "webViewer") {
          //webviewer
          draft[4].isActive = activeModules[key];
        }
        //  else if (key === "userSettings") {
        //   // user settings
        //   draft[5].isActive = activeModules[key];
        // }
        // } else if (key === "riskReport") {
        //   // riskReport profile
        //   draft[5].isActive = activeModules[key];
        // }
      });
      setIsActiveModuleLoaded(false);
    });
  }, [activeModules, userData]);

  // trigger #2
  useEffect(() => {
    if (!isAuth) return;

    if (!activeModules) {
      let agsKey = "";
      if (searchParams.get("ags")) {
        agsKey = searchParams.get("ags");
      } else {
        agsKey = ags;
      }
      if (agsKey) dispatch(actions.customerAction(agsKey));
    }
  }, [isAuth, dispatch, ags]);

  // trigger #1
  // call /user/me to get user data
  useEffect(() => {
    if (!isAuth) return;
    dispatch(actions.userAction());
  }, [isAuth]);

  //  Handle unknown path
  // --------------------------------
  // 1. Authenticated user -> Show 404 page
  // 2. Unauthenticated user -> Redirect to /login page >
  const handleUnknownPath = () => {
    return (
      <Route
        key="/*"
        path="/*"
        element={
          isAuth ? (
            !isUserLoading && !isCustomerLoading && isActiveModuleLoaded ? (
              appLoader
            ) : (
              <NotFoundPage />
            )
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
    );
  };

  // prevent full app loader on every me api call (e: Notification tab)
  // if (
  //   isUserLoading &&
  //   (window.location.pathname === "/" ||
  //     window.location.pathname === "/realtimemap")
  // )
  //   return appLoader;
  if (isUserLoading) return appLoader;

  return (
    <>
      <AppLayout isAuthenticated={isAuth} />
      <Routes>
        {/* Home page */}
        {activeModules && (
          <Route
            key="/"
            path="/"
            element={
              <Navigate
                replace
                to={
                  isAuth
                    ? activeModules["realtimeMap"]
                      ? "/realtimemap"
                      : `/webviewer?ags=${ags}`
                    : "/"
                }
              />
            }
          />
        )}

        {/*==================
          private routes 
        ===================*/}
        {privateRoutes.map((route) => {
          if (appMode === "observer") {
            // Allowed Webviewer, if particular observer area is selected
            if (route.path === "/webviewer") {
              if (!selectedAgs) return null;
            }

            // if (
            //   route.path === "/usermanagement" ||
            //   route.path === "/analytics"
            // ) {
            //   return (
            //     (userRoles[selectedAgs] || []).includes("managers") && (
            //       <Route
            //         key={route.path}
            //         path={route.path}
            //         element={<PrivateRoute>{route.components}</PrivateRoute>}
            //       />
            //     )
            //   );
            // }

            // ---------------
            //No need to check activeModule for realtime-map, sensor-manager and webviewer , because these are always active for observer

            return (
              route.allowedUser.includes(appMode) && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<PrivateRoute>{route.components}</PrivateRoute>}
                />
              )
            );
          } else {
            return (
              route.allowedUser.includes(userRole) &&
              route.isActive && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<PrivateRoute>{route.components}</PrivateRoute>}
                />
              )
            );
          }
        })}

        {/*==================
          public routes 
        ===================*/}
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<PublicRoute>{route.components}</PublicRoute>}
            />
          );
        })}

        {/* Default routes */}
        {/* <Route key="/risk" path="/risk" element={<RiskForm />} /> */}
        {isAuth && (
          <Route key="/setting" path="/setting" element={<UserSettings />} />
        )}
        <Route
          key="/change-email"
          path="/change-email/:token"
          element={<ChangeEmail />}
        />

        {handleUnknownPath()}
      </Routes>
    </>
  );
};

export default AppRouter;
