/*
 * Title: smartsewer reducer
 * Description: smartsewer sensor reducer
 */

import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const smartsewerInit = (state) => {
  return updateObject(state, {
    isLoading: true,
    data: null,
    error: null,
  });
};

const smartsewerSuccess = (state, data) => {
  return updateObject(state, {
    isLoading: false,
    data: data,
    error: null,
  });
};

const smartsewerFail = (state, error) => {
  return updateObject(state, {
    isLoading: false,
    data: null,
    error: error,
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, error } = action;
  switch (type) {
    case actionTypes.SMARTSEWER_INIT:
      return smartsewerInit(state);
    case actionTypes.SMARTSEWER_SUCCESS:
      return smartsewerSuccess(state, data);
    case actionTypes.SMARTSEWER_FAIL:
      return smartsewerFail(state, error);
    default:
      return state;
  }
};

export default reducer;
