import { updateObject } from "../../../shared/Utility";
import * as actionTypes from "../../actions/actionTypes";

const initialState = {
  preserveState: {
    building: false,
    n30: false,
    n100: false,
    n1000: false,
    n1: false,
    n5: false,
    riskarea: false,
    floodtype1: false,
    floodtype2: false,
    floodtype3: false,
    harmReport: {
      flag: false,
      option: "Alle",
    },
    harmNote: false,
  },
  mapView: {
    center: null,
    zoom: null,
  },
};

const updateMetaData = (state, data) => {
  return updateObject(state, {
    preserveState: { ...state.preserveState, ...data },
  });
};

const saveMapView = (state, center, zoom) => {
  return updateObject(state, {
    mapView: { center, zoom },
  });
};

const reducer = (state = initialState, action) => {
  const { type, data, center, zoom } = action;
  switch (type) {
    case actionTypes.WEBVIEW_UPDATE_META_DATA:
      return updateMetaData(state, data);
    case actionTypes.SAVE_REALTIME_MAP_VIEW:
      return saveMapView(state, center, zoom);
    default:
      return state;
  }
};

export default reducer;
