import * as actionTypes from "../actionTypes";

export const updateWebviewerStates = (data) => {
  return {
    type: actionTypes.WEBVIEW_UPDATE_META_DATA,
    data,
  };
};

export const saveRealtimeMapView = (center, zoom) => {
  return {
    type: actionTypes.SAVE_REALTIME_MAP_VIEW,
    center,
    zoom,
  };
};
